const useCookie = (cookieName: string) => {
  const cookieString = document.cookie
    .split(';')
    .find((cookie) => cookie.split('=')[0] === ` ${cookieName}`);

  if (!cookieString) {
    return null;
  }

  const cookieInfo = cookieString.split('=')[1].split('|');

  return {
    value: cookieInfo[0]
  };
};

export default useCookie;

import { IRoutes } from '../Types/IRoutes';
import React from 'react';

const Steps = React.lazy(() =>
  import('../Pages/').then((components) => ({ default: components.Steps }))
);
const NotFound = React.lazy(() =>
  import('../Pages/').then((components) => ({ default: components.NotFound }))
);
const Login = React.lazy(() =>
  import('../Pages/').then((components) => ({ default: components.Login }))
);
const Probe = React.lazy(() =>
  import('../Pages/').then((components) => ({ default: components.Probe }))
);
const Error = React.lazy(() =>
  import('../Pages/').then((components) => ({ default: components.Error }))
);
const Setup = React.lazy(() =>
  import('../Pages/').then((components) => ({ default: components.Setup }))
);

const publicRoutes: IRoutes[] = [
  {
    name: 'login',
    path: 'login',
    pageComponent: Login
  },
  {
    name: 'probe',
    path: 'probe',
    pageComponent: Probe
  },
  {
    name: 'error',
    path: 'error',
    pageComponent: Error
  }
];

const protectedRoutes: IRoutes[] = [
  {
    name: 'index',
    path: '',
    pageComponent: Steps,
    nestedRoutes: [
      {
        name: 'setup',
        path: 'setup',
        pageComponent: Setup
      }
    ]
  }
];

const NotFoundRoute: IRoutes = {
  name: 'notFound',
  path: '*',
  pageComponent: NotFound
};

export default { publicRoutes, protectedRoutes, NotFoundRoute };

import useCookie from 'Hooks/useCookie';
import useHTTP from 'Hooks/useHTTP';
import { createContext, useState, PropsWithChildren } from 'react';
import IAuthContext from '../../../Types/IAuthContext';

const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const DNA = useCookie('DNA');

  const [auth, setAuth] = useState<boolean>(!!DNA);
  const http = useHTTP();

  http.axiosInstance.interceptors.response.use(
    (successData) => successData,
    (error) => {
      if (error.response.status === 401) {
        setAuth(false);
      }
      throw error;
    }
  );

  return <AuthContext.Provider value={{ auth }}>{children}</AuthContext.Provider>;
};

export default AuthContext;

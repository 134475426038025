import IBusinessRole from 'Types/IBusinessRole';
import { TWizardState } from 'Types/IWizardContext';
import { AxiosInstance } from 'axios';
import IStore from 'Types/IStore';
import AxiosRequest from 'Utils/AxiosRequest';

export default class HTTPService {
  private static instance: HTTPService;

  public static getInstance(): HTTPService {
    if (!HTTPService.instance) {
      HTTPService.instance = new HTTPService();
    }

    return HTTPService.instance;
  }

  private _Axios: AxiosInstance;

  get axiosInstance(): AxiosInstance {
    return this._Axios;
  }

  constructor() {
    this._Axios = AxiosRequest;
  }

  public getStores = async (): Promise<IStore[]> => {
    const { data } = await this._Axios.get<IStore[]>('/adm-provisioner/stores', {
      auth: {
        username: 'prov',
        password: 'uolTk232%#!'
      }
    });

    // Mock data
    // return [
    //   ...data,
    //   {
    //     email: 'asdasd',
    //     idEmpresa: null,
    //     idOrder: 23424234,
    //     idUser: 1,
    //     idOrderAppStatus: 1,
    //     idOrderStatus: 1,
    //     idUserStatus: 1,
    //     pastaLoja: null
    //   },
    //   {
    //     email: 'asdasd',
    //     idEmpresa: null,
    //     idOrder: 678678678,
    //     idUser: 1,
    //     idOrderAppStatus: 1,
    //     idOrderStatus: 1,
    //     idUserStatus: 1,
    //     pastaLoja: null
    //   }
    // ];
    return data.filter((store) => store.idOrderStatus === 1);
  };

  public domainIsValid = async (domain: string): Promise<boolean> => {
    const {
      data: { result }
    } = await this._Axios.get<{ result: boolean }>(`/wizard/virtualhost/exists?dominio=${domain}`);
    return !result;
  };

  private _configStore = async ({
    storeData,
    businessLine,
    temporaryDomain,
    idCompany
  }: Required<Omit<TWizardState, 'storeName'>> & { idCompany: string }): Promise<void> => {
    console.log('Config Store');
    await this._Axios.post(
      `/wizard/company/configure/user/${storeData.idUser}/order/${storeData.idOrder}/company/${idCompany}`,
      {
        virtualHostProvisioning: temporaryDomain,
        businessSector: businessLine
      }
    );
  };

  private _createStore = async ({
    storeName,
    storeData,
    businessLine,
    temporaryDomain
  }: Required<TWizardState>): Promise<string> => {
    console.log('Create Store');
    const {
      data: { result: idCompany }
    } = await this._Axios.post(`/wizard/company/create/order/${storeData.idOrder}`, {
      name: storeName,
      virtualHostProvisioning: temporaryDomain,
      businessSector: businessLine
    });
    return idCompany;
  };

  private _rollbackStore = async (idCompany: string) => {
    this._Axios.delete(`/wizard/company/remove/company/${idCompany}`);
  };

  public createStore = async (data: Required<TWizardState>): Promise<void> => {
    const idCompany = await this._createStore(data);

    try {
      await this._configStore({ ...data, idCompany });
    } catch (e) {
      await this._rollbackStore(idCompany);
      throw e;
    }
  };

  getBusinessRole = async (): Promise<IBusinessRole[]> => {
    const {
      data: { result }
    } = await this._Axios.get<{ result: IBusinessRole[] }>('/wizard/business-sector');
    return result;
  };
}

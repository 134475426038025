import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../Hooks/useAuth';
import IAuthContext from '../../Types/IAuthContext';

const RequireAuth = () => {
  const { auth } = useAuth() as IAuthContext;
  const location = useLocation();

  return !!auth ? <Outlet /> : <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;

import S from './App.style.module.scss';
import './Global/Style/globalStyle.scss';
import { Outlet } from 'react-router-dom';
import { WizardProvider } from './Global/Context/WizardContext';
import ErrorBoundary from 'App/ErrorBoundary';

const App = () => {
  return (
    <ErrorBoundary>
      <WizardProvider>
        <main className={S.container}>
          <Outlet />
        </main>
      </WizardProvider>
    </ErrorBoundary>
  );
};

export default App;

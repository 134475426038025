import React from 'react';
import { Navigate } from 'react-router-dom';

interface IErrorBoundary {
  hasError: boolean;
  Error?: Error;
}

export default class ErrorBoundary extends React.Component<
  React.PropsWithChildren,
  IErrorBoundary
> {
  constructor(base: React.PropsWithChildren & IErrorBoundary) {
    super(base);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // logcenter(error, errorInfo)
    console.log(error);
    console.log(errorInfo);

    this.setState({
      hasError: true
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    return !hasError ? children : <Navigate to="error?code=500" />;
  }
}

import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true;
axios.defaults.timeout = 20000;

const AxiosRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL ?? 'https://api.lojavirtuol.uol.com.br/'
});

AxiosRequest.interceptors.request.use((config) => {
  const newConfig = config;
  if (newConfig.headers) {
    newConfig.headers['Correlation-ID'] = uuidv4();
    newConfig.headers['Impersonate-Token'] = uuidv4();
  }
  return newConfig;
});

export default AxiosRequest;

import React, { Suspense } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import pageRoutes from './Routes';
import { IRoutes } from '../Types/IRoutes';
import { App } from '../App';
import RequireAuth from '../Utils/RequireAuth';
import { AuthProvider } from '../App/Global/Context/AuthContext';
import GA from 'Utils/GA';

const generateRoute = (routeInfo: IRoutes) => (
  <Route key={routeInfo.name} path={routeInfo.path} element={<routeInfo.pageComponent />}>
    {routeInfo.nestedRoutes &&
      routeInfo.nestedRoutes.map((nestedRoute) => generateRoute(nestedRoute))}
  </Route>
);

const Router: React.FC = () => {
  const { publicRoutes, protectedRoutes, NotFoundRoute } = pageRoutes;
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route
            element={
              <Suspense>
                <Outlet />
              </Suspense>
            }
          />
          <Route path="/" element={<GA />}>
            {publicRoutes.map((pageRoute) => generateRoute(pageRoute))}
            <Route element={<RequireAuth />}>
              <Route path="/" element={<App />}>
                {protectedRoutes.map((pageRoute) => generateRoute(pageRoute))}
              </Route>
              {generateRoute(NotFoundRoute)}
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Router;

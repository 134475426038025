import useHTTP from 'Hooks/useHTTP';
import React, { createContext, PropsWithChildren, useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import IWizardContext, { TWizardAction, TWizardState } from 'Types/IWizardContext';

const initialState: TWizardState = {
  storeName: undefined,
  temporaryDomain: undefined,
  businessLine: {
    id: 27,
    description: 'Outros',
    ordenation: 28
  },
  storeData: undefined
};

const WizardStateReducer: React.Reducer<TWizardState, TWizardAction> = (state, action) => {
  const { type, payload } = action;
  switch (type) {
    case 'STEP_1':
      return { ...state, storeName: payload.storeName, temporaryDomain: payload.temporaryDomain };
    case 'STEP_2':
      return { ...state, businessLine: payload.businessLine };
    case 'STORE_DATA':
      return { ...state, storeData: { ...payload } };
    case 'localStorageRestore':
      return { ...payload };
    default:
      return { ...state };
  }
};

const WizardContext = createContext<IWizardContext>({} as IWizardContext);

export const WizardProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [wizardState, changeWizardState] = useReducer(WizardStateReducer, initialState);
  const [params] = useSearchParams();
  const paramsStoreId = params.get('id') || '';

  const { getStores } = useHTTP();

  useEffect(() => {
    (async () => {
      const stores = await getStores();
      const pendingWizardStores = stores.filter((store) => store.idOrderAppStatus === 1);
      const pendingWizardStore =
        stores.find((store) => store.idOrder === parseInt(paramsStoreId)) || pendingWizardStores[0];

      if (!pendingWizardStore) {
        return (document.location = stores.length
          ? 'https://painel.lojavirtuol.uol.com.br/'
          : 'https://meunegocio.uol.com.br/loja-virtual');
      }

      changeWizardState({ type: 'STORE_DATA', payload: pendingWizardStore });
    })();
  }, [getStores, paramsStoreId]);

  const state: IWizardContext = {
    ...wizardState,
    changeWizardState
  };

  return (
    <WizardContext.Provider value={state}>
      {wizardState.storeData && children}
    </WizardContext.Provider>
  );
};

export default WizardContext;

import { Outlet, useLocation } from 'react-router-dom';
import { pageview, event, EventArgs } from 'react-ga';

import React, { PropsWithChildren, useEffect } from 'react';

const TriggerMap = {
  Clique: 'onClick',
  Alterar: 'onChange',
  Foco: 'onFocus'
} as const;

type TGAAction = {
  action: keyof typeof TriggerMap;
};

export const GaEvent: React.FC<PropsWithChildren<EventArgs & TGAAction>> = ({
  children,
  ...props
}) => {
  if (!React.isValidElement(children)) {
    return <></>;
  }

  return React.cloneElement(children, {
    ...children.props,
    [TriggerMap[props.action]]: (p: unknown) => {
      event(props);
      if (children.props[TriggerMap[props.action]]) {
        children.props[TriggerMap[props.action]](p);
      }
    }
  });
};

function GA() {
  const location = useLocation();
  useEffect(() => {
    pageview(window.location.pathname + window.location.search);
  }, [location]);
  return <Outlet />;
}

export default GA;
